<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Gönderim Tarihi"
          label-for="send_date"
        >
          <b-form-datepicker
            id="send_date"
            v-model="dataItem.send_date"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Geliş Tarihi"
          label-for="arrival_date"
        >
          <b-form-datepicker
            id="arrival_date"
            v-model="dataItem.arrival_date"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Tamir Şirketi"
          label-for="id_com_damage_repair_company"
        >
          <validation-provider
            #default="{ errors }"
            name="Tamir Şirketi"
            rules="required"
          >
            <v-select
              id="id_com_damage_repair_company"
              v-model="dataItem.id_com_damage_repair_company"
              label="title"
              :reduce="item => item.id"
              :options="repairCompany"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Durum Tipi"
          label-for="id_com_damage_repair_status"
        >
          <validation-provider
            #default="{ errors }"
            name="Durum Tipi"
            rules="required"
          >
            <v-select
              id="id_com_damage_repair_status"
              v-model="dataItem.id_com_damage_repair_status"
              label="title"
              :reduce="item => item.id"
              :options="repairStatuses"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group
      label="Parça Adı"
      label-for="component_name"
    >
      <validation-provider
        #default="{ errors }"
        name="Parça Adı"
        rules="required"
      >
        <b-form-textarea
          v-model="dataItem.component_name"
          placeholder="Parça Adı"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Doküman"
      label-for="upload_file"
    >
      <b-form-file
        v-model="dataItem.upload_file"
        placeholder="Bir dosya seçin veya buraya bırakın..."
        drop-placeholder="Dosya buraya bırakın..."
        browse-text="Dosya Seçin"
      />

    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BFormDatepicker,
  BFormGroup, BFormTextarea, BRow,
  BCol, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RepairForm',
  components: {
    BFormFile,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['damageRepair/getData']
    },
    repairStatuses() {
      return this.$store.getters['damageRepairStatus/getDataList']
    },
    repairCompany() {
      return this.$store.getters['damageRepairCompany/getDataList']
    },
  },
  created() {
    this.getStatuses()
    this.getCompanies()
  },
  methods: {
    getStatuses() {
      return this.$store.dispatch('damageRepairStatus/dataList')
    },
    getCompanies() {
      return this.$store.dispatch('damageRepairCompany/dataList')
    },
  },
}
</script>
